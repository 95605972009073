<template>
	<base-section id="sluzby" class="services">
		<v-container>
			<v-row class="text-center">
				<v-col cols="12">
					<h2 class="display-1 text-uppercase primary--text">
						Vyberte si z našich služeb
					</h2>
				</v-col>
			</v-row>
			<v-row style="height: 100%">
				<v-col cols="12" md="4" class="service">
					<v-card class="service-card">
							<v-system-bar
							color="primary"
							dark
							>
							</v-system-bar>
						<v-card-text class="text-center">
							<h3 class="font-weight-bold text-uppercase">Pronájem vozidel</h3>
						</v-card-text>
						<v-card-text class="text-center text-caption mb-n8">
								K jakémukoli pronájmu vždy přistupujeme s individuální péčí. Pro Váš úplný komfort tato služba zahrnuje veškerý servis:
						</v-card-text>
						<v-card-text>
							<div class="service-items">
								<div>
									<v-icon class="primary--text">mdi-menu-right</v-icon> 
								</div>
								<div>
									Sjednání a zajištění financování
								</div>
							</div>
							<div class="service-items">
								<div>
									<v-icon class="primary--text">mdi-menu-right</v-icon> 
								</div>
								<div>
									Autorizovaný servis a asistenční služby
								</div>
							</div>
							<div class="service-items">
								<div>
									<v-icon class="primary--text">mdi-menu-right</v-icon> 
								</div>
								<div>
									Kompletní pojištění
								</div>
							</div>
							<div class="service-items">
								<div>
									<v-icon class="primary--text">mdi-menu-right</v-icon> 
								</div>
								<div>
									Náhradní vozidlo v případě poruchy
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" md="4" class="service">
					<v-card class="service-card">
							<v-system-bar
							color="black"
							dark
							>
							</v-system-bar>
						<v-card-text class="text-center">
							<h3 class="font-weight-bold text-uppercase">Správa vozového parku</h3>
						</v-card-text>
						<v-card-text class="text-center text-caption mb-n8">
							Vašemu vozovému parku věnujeme nadstandardní péči a děláme věci správně. Umíme přistoupit k jakémukoli vozovému parku:  
						</v-card-text>
						<v-card-text>
							<div class="service-items">
								<div>
									<v-icon class="primary--text">mdi-menu-right</v-icon> 
								</div>
								<div>
									Kompletní správa a řízení vozověho parku na klíč
								</div>
							</div>
							<div class="service-items">
								<div>
									<v-icon class="primary--text">mdi-menu-right</v-icon> 
								</div>
								<div>
									Analýza cash flow vašeho vozového parku
								</div>
							</div>
							<div class="service-items">
								<div>
									<v-icon class="primary--text">mdi-menu-right</v-icon> 
								</div>
								<div>
									Optimalizace procesů a řízení
								</div>
							</div>
							<div class="service-items">
								<div>
									<v-icon class="primary--text">mdi-menu-right</v-icon> 
								</div>
								<div>
									Poradenství při zakládání nového vozového parku
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" md="4" class="service">
					<v-card class="service-card">
							<v-system-bar
							color="grey"
							dark
							>
							</v-system-bar>
						<v-card-text class="text-center">
							<h3 class="font-weight-bold text-uppercase">Komplexní poradenství</h3>
						</v-card-text>
						<v-card-text class="text-center text-caption mb-n8">
							V rámci spolupráce se s Vámi rádi podělíme o naše dlouholeté zkušenosti v oboru automobility a firemních flotil:
						</v-card-text>
						<v-card-text>
							<div class="service-items">
								<div>
									<v-icon class="primary--text">mdi-menu-right</v-icon> 
								</div>
								<div>
									Konzultace výběru typu služby: úvěr nebo leasing 
								</div>
							</div>
							<div class="service-items">
								<div>
									<v-icon class="primary--text">mdi-menu-right</v-icon> 
								</div>
								<div>
									Doporučení vhodného vozidla pro Vaše potřeby
								</div>
							</div>
							<div class="service-items">
								<div>
									<v-icon class="primary--text">mdi-menu-right</v-icon> 
								</div>
								<div>
									Příprava nejvýhodnější nabídky v poměru „cena/výkon“
								</div>
							</div>
							<div class="service-items">
								<div>
									<v-icon class="primary--text">mdi-menu-right</v-icon> 
								</div>
								<div>
									Výběr vhodného finančního a pojistného partnera
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</base-section>
</template>

<script>
export default {
	name: "SectionMarketing",

	data: () => ({
		
	}),

	methods: {
	
	}

};
</script>
<style scoped>
.service-items {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	margin-top: 1rem;
}

@media screen and (max-width: 1000px) {
	.service-items {
		justify-content: flex-start;
	}
}

.service-card {
	min-height: 20rem !important;
}

</style>
